import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
@Component({
  selector: 'rcp-date-selector',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    CalendarModule,
    DialogModule,
  ],
  templateUrl: './date-selector.component.html',
  styleUrl: './date-selector.component.scss',
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})
export class DateSelectorComponent implements OnInit, OnChanges {
  @ViewChild('datePicker') datePicker: any;
  @Input() calenderDateInputPlaceholder: string;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() maxDateOfFirstSelectionDate: Date;
  @Input() defaultStartDate: Date | undefined;
  @Input() defaultEndDate: Date | undefined;
  @Input() isClearSearchData = false;
  @Input() isAddLast24HoursOption: boolean;
  @Input() isAddLast7DaysOption: boolean;
  @Input() isAddLast2WeeksOption: boolean;
  @Input() isAddLast30DaysOption: boolean;
  @Input() isAddLast90DaysOption: boolean;
  @Input() isAddLastYearOption: boolean;
  @Input() defaultDropdownOption: any;
  @Input() showTitleBar = false;
  @Input() showDateRangeInput = false;
  @Input() shouldAutoClose = true;
  @Output() dateSearchEvent = new EventEmitter();
  @Output() clearSearchDataEvent = new EventEmitter();
  @Output() startDataEvent = new EventEmitter();
  @Output() endDataEvent = new EventEmitter();
  @Output() dropdownOptionSelectedEvent = new EventEmitter();
  startDate: Date | undefined;
  endDate: Date | undefined;
  defaultMaxDate: Date;
  dropdownOptions: any[] | undefined;
  selectedOption: any = null;
  selectedDates: Date[];
  dateRangeFormat = 'YYYY-MM-DD';
  dateRange = this.dateRangeFormat;
  dateFormat = 'yyyy-MM-dd';
  startDateString: string | null = null;
  endDateString: string | null = null;
  isSearchButtonDisable = true;
  searchBtnLabel = 'Search';
  dropdownPlaceholder = 'Select range';
  popupTitle = 'Select a date';
  afterSearchStartDate: Date | undefined;
  afterSearchEndDate: Date | undefined;
  afterSearchSelectedOption: any = null;
  afterSearchDateRange = this.dateRangeFormat;
  afterMaxDateOfFirstSelectionDate: Date = null;
  isFirstMax = false;
  constructor(private readonly datePipe: DatePipe) {}
  ngOnChanges() {
    this.maxDate = this.maxDateOfFirstSelectionDate
      ? this.maxDateOfFirstSelectionDate
      : this.maxDate;
    if (this.isClearSearchData) {
      this.clearDate();
    }
    this.setByDefaultValueOfStartAndEndDate();
  }
  ngOnInit() {
    this.calenderDateInputPlaceholder = this.calenderDateInputPlaceholder
      ? this.calenderDateInputPlaceholder
      : 'Last received date';
    const today = new Date();
    this.defaultMaxDate = this.maxDate
      ? this.maxDate
      : new Date(today.getFullYear(), today.getMonth() + 2, 0); // Next month last date
    this.maxDate = this.defaultMaxDate;
    this.dropdownOptions = [];
    if (this.isAddLast24HoursOption) {
      this.dropdownOptions.push({
        label: 'Last 24 hours',
        value: 'last24Hours',
      });
    }
    if (this.isAddLast7DaysOption) {
      this.dropdownOptions.push({ label: 'Last 7 days', value: 'last7Days' });
    }
    if (this.isAddLast2WeeksOption) {
      this.dropdownOptions.push({ label: 'Last 2 weeks', value: 'last2Weeks' });
    }
    if (this.isAddLast30DaysOption) {
      this.dropdownOptions.push({ label: 'Last 30 days', value: 'last30Days' });
    }
    if (this.isAddLast90DaysOption) {
      this.dropdownOptions.push({ label: 'Last 90 days', value: 'last90Days' });
    }
    if (this.isAddLastYearOption) {
      this.dropdownOptions.push({ label: 'Last year', value: 'lastYear' });
    }
  }
  setByDefaultValueOfStartAndEndDate() {
    if (this.defaultStartDate && this.defaultEndDate) {
      this.selectedDates = [this.defaultStartDate, this.defaultEndDate];
      this.defaultStartDate = null;
      this.defaultEndDate = null;
      this.isSearchButtonDisable = false;
      this.startDateString = this.datePipe.transform(
        this.selectedDates[0],
        this.dateFormat
      );
      this.endDateString = this.datePipe.transform(
        this.selectedDates[1],
        this.dateFormat
      );
      this.dateRange = `${this.startDateString} - ${this.endDateString}`;
      this.afterSearchStartDate = this.selectedDates[0];
      this.afterSearchEndDate = this.selectedDates[1];
      this.afterSearchDateRange = this.dateRange;
      this.afterSearchSelectedOption = this.defaultDropdownOption;
      this.selectedOption = this.defaultDropdownOption;
      this.dateSearchEvent.emit(this.selectedDates);
    }
  }
  onDropdownChange(event: any) {
    this.maxDate = this.defaultMaxDate;
    this.maxDateOfFirstSelectionDate = null;
    this.updateDateRange(this.selectedOption);
  }
  updateDateRange(option: any) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    switch (option.value) {
      case 'last24Hours': {
        this.startDate = new Date();
        this.startDate.setHours(this.startDate.getHours() - 24);
        break;
      }
      case 'last7Days':
        this.startDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 7
        );
        break;
      case 'last2Weeks':
        this.startDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 15
        );
        break;
      case 'last30Days':
        this.startDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 30
        );
        break;
      case 'last90Days':
        this.startDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 90
        );
        break;
      case 'lastYear':
        this.startDate = new Date(today.getFullYear() - 1, 0, 1);
        break;
      default:
        this.startDate = today;
    }
    if (option.value === 'lastYear') {
      this.endDate = new Date(today.getFullYear() - 1, 11, 31);
    } else {
      this.endDate = today;
      this.endDate.setDate(this.endDate.getDate() - 1);
    }
    this.setDateRangeStringValue();
    this.selectedDates = [this.startDate, this.endDate];
    if (this.startDate && this.endDate) {
      this.isSearchButtonDisable = false;
    }
    this.highlightSelectedDates();
  }
  setToday() {
    this.maxDate = this.defaultMaxDate;
    this.datePicker.overlayVisible = true;
    this.selectedOption = null;
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    this.startDate = todayStart;
    this.endDate = todayEnd;
    this.selectedDates = [this.startDate, this.endDate];
    this.setDateRangeStringValue();
    this.isSearchButtonDisable = false;
  }
  clearDate(isComingFromDateSelectorComponent = false) {
    this.maxDate = this.defaultMaxDate;
    this.afterSearchStartDate = undefined;
    this.afterSearchEndDate = undefined;
    this.afterSearchSelectedOption = null;
    this.maxDateOfFirstSelectionDate = null;
    this.afterMaxDateOfFirstSelectionDate = null;
    this.afterSearchDateRange = this.dateRangeFormat;
    if (isComingFromDateSelectorComponent) {
      this.datePicker.overlayVisible = true;
    }
    this.dateRange = this.dateRangeFormat;
    this.selectedOption = null;
    this.defaultDropdownOption = null;
    this.selectedDates = null;
    this.startDate = null;
    this.endDate = null;
    this.isSearchButtonDisable = true;
    this.isClearSearchData = false;
    this.defaultStartDate = null;
    this.defaultEndDate = null;
    this.clearSearchDataEvent.emit(this.selectedDates);
  }
  closeCalendar() {
    this.maxDate = this.defaultMaxDate;
    this.datePicker.overlayVisible = false;
  }
  search() {
    this.afterSearchStartDate = this.selectedDates[0];
    this.afterSearchEndDate = this.selectedDates[1];
    this.afterSearchSelectedOption = this.selectedOption;
    this.afterSearchDateRange = this.dateRange;
    this.afterMaxDateOfFirstSelectionDate = this.maxDateOfFirstSelectionDate;
    if (this.afterSearchSelectedOption) {
      this.dropdownOptionSelectedEvent.emit(this.afterSearchSelectedOption);
    } else {
      this.dropdownOptionSelectedEvent.emit({});
    }
    if (this.selectedDates[0] != null && this.selectedDates[1] != null) {
      this.datePicker.overlayVisible = false;
      this.isFirstMax = true;
      this.dateSearchEvent.emit(this.selectedDates);
    }
  }
  closeEvent() {
    if (this.isFirstMax && this.afterMaxDateOfFirstSelectionDate) {
      this.maxDate = this.afterMaxDateOfFirstSelectionDate;
    } else {
      this.maxDate = this.defaultMaxDate;
    }
    this.dateRange = this.afterSearchDateRange;
    this.selectedOption = this.afterSearchSelectedOption;
    if (this.afterSearchStartDate && this.afterSearchEndDate) {
      this.selectedDates = [this.afterSearchStartDate, this.afterSearchEndDate];
      this.isSearchButtonDisable = false;
    } else {
      this.selectedDates = null;
      this.isSearchButtonDisable = true;
    }
  }
  onSelect() {
    this.datePicker.overlayVisible = true;
    this.selectedOption = null;
    this.startDate = this.selectedDates[0];
    this.endDate = this.selectedDates[1];
    this.setDateRangeStringValue();
    if (this.startDate) {
      if (!this.endDate) {
        this.highLightStartDate();
      }
      this.startDataEvent.emit(this.startDate);
    }
    if (this.endDate) {
      this.endDataEvent.emit(this.endDate);
      if (this.shouldAutoClose) {
        this.search();
        return;
      }
    }
    if (this.startDate && this.endDate) {
      this.isSearchButtonDisable = false;
    } else {
      this.isSearchButtonDisable = true;
    }
  }
  setDateRangeStringValue() {
    if (this.startDate) {
      this.startDateString = this.datePipe.transform(
        this.startDate,
        this.dateFormat
      );
      this.dateRange = `${this.startDateString}`;
    }
    if (this.endDate) {
      this.endDateString = this.datePipe.transform(
        this.endDate,
        this.dateFormat
      );
      this.dateRange = `${this.dateRange} - ${this.endDateString}`;
    }
  }
  /**
   * below function will be called when user selected the start date
   */
  highLightStartDate() {
    setTimeout(() => {
      const calendarContainer = this.datePicker.contentViewChild
        ?.nativeElement as HTMLElement;
      if (calendarContainer) {
        const highLightedEle =
          calendarContainer.querySelector('span.p-highlight');
        const oldHighLightedEle =
          calendarContainer.querySelectorAll('.cp-startDate');
        if (oldHighLightedEle.length > 0) {
          oldHighLightedEle.forEach((ele: HTMLElement) => {
            ele.classList.remove('cp-startDate');
          });
        }
        if (highLightedEle) {
          highLightedEle.classList.add('cp-startDate');
        }
      } else {
        console.warn(
          'dateselectorComponent: unable to read calendar as native element'
        );
      }
    }, 0);
  }
  /**
   * below function will get called when date picker opens
   */
  highlightSelectedDates() {
    setTimeout(() => {
      const calendarContainer = this.datePicker.contentViewChild
        ?.nativeElement as HTMLElement;
      if (this.selectedDates?.[0] && this.selectedDates?.[1]) {
        const startDate = this.formatDateKey(this.selectedDates[0]);
        const endDate = this.formatDateKey(this.selectedDates[1]);
        if (calendarContainer) {
          calendarContainer
            .querySelector(`span[data-date='${startDate}']`)
            ?.classList?.add('cp-startDate');
          calendarContainer
            .querySelector(`span[data-date='${endDate}']`)
            ?.classList?.add('cp-endDate');
        } else {
          console.warn(
            'dateselectorComponent: unable to read calendar as native element'
          );
        }
      } else {
        console.warn('dateselectorComponent: proper dates are not selected');
      }
    }, 0);
  }
  formatDateKey(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  }
}
