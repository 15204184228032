<div>
  <p-calendar
    #datePicker
    [(ngModel)]="selectedDates"
    [showButtonBar]="true"
    [iconDisplay]="'input'"
    [showIcon]="true"
    [readonlyInput]="true"
    [maxDate]="maxDate"
    [minDate]="minDate"
    (onSelect)="onSelect()"
    (onClearClick)="clearDate(true)"
    (onTodayClick)="setToday()"
    (onClose)="closeEvent()"
    (onShow)="highlightSelectedDates()"
    (onMonthChange)="highlightSelectedDates()"
    appendTo="body"
    inputId="icondisplay"
    showOtherMonths="{{ 1 !== 1 }}"
    selectionMode="range"
    placeholder="{{ calenderDateInputPlaceholder }}"
    hideOnDateTimeSelect="false"
    dateFormat="yy-mm-dd"
    todayButtonStyleClass="hidden"
  >
    <ng-template pTemplate="header">
      <div class="flex flex-column gap-3">
        @if(showTitleBar){
        <div class="flex justify-content-between">
          <div
            class="flex align-items-center justify-content-center popup-title"
          >
            {{ popupTitle }}
          </div>
          <div class="flex align-items-center justify-content-center">
            <i class="close-icon" (click)="closeCalendar()"></i>
          </div>
        </div>
        }
        <div class="flex flex-column gap-2">
          @if(showDateRangeInput){
          <div>
            <input
              type="text"
              class="max-width inline-h"
              pInputText
              [(ngModel)]="dateRange"
              [readonly]="true"
            />
          </div>
          }
          <div>
            <p-dropdown
              [options]="dropdownOptions"
              [(ngModel)]="selectedOption"
              (onChange)="onDropdownChange($event)"
              optionLabel="label"
              [style]="{ width: '100%' }"
              placeholder=" {{ dropdownPlaceholder }}"
            />
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div>
        <div class="flex flex-grow mt-2">
          <button
            type="button"
            class="btn-primary-lg"
            [disabled]="isSearchButtonDisable"
            (click)="search()"
          >
            {{ searchBtnLabel }}
          </button>
        </div>
      </div>
    </ng-template>
  </p-calendar>
</div>
